import React from "react"
import Layout from "../components/Layout"

export default function Impressum() {
  return (
    <Layout>
      <div className="deco">
          <img
            src="/waxenstein.jpg" alt=""
          />        
      </div>
      <div className="content">
        <h1>Impressum</h1>
        <p className="no-margin">
          Ein Service der{" "}
          <a
            href="http://www.kanzlei-schweikert.de"
            rel="noreferrer"
            target="_blank"
          >
            Rechtsanwaltskanzlei Friedrich Schweikert.
          </a>
        </p>
        <p>
          Telefon: 08061- 938689- 5 &nbsp;&nbsp; (Bitte lange klingeln lassen)
          <br />
          Telefax: 08061- 938689- 4
        </p>
        <p>
          Adresse: <br />
          <b>Kanzlei Schweikert</b>,<br />
          Lagerhausstr.12, 83043 Bad Aibling
          <br />
          <b>
            <u>Postanschrift:</u> Postfach 1248, in 83036 Bad Aibling
          </b>          
        </p>
        <p>
          Mail:{" "}
          <img
            src="/email_black.gif"
            alt="Emailadresse"
            style={{ verticalAlign: "middle" }}
          />          
        </p>
        <p className="bold">
          Weitere{" "}
          <a
            href="http://www.kanzlei-schweikert.de/informationen.html"
            rel="noreferrer"
            target="_blank"
          >
            Informationen
          </a>{" "}
          - insbesondere nach DL-InfoV - unter
          http://www.kanzlei-schweikert.de/informationen.html
        </p>
        <p>
          www.kanzlei-schweikert.de / www.ra-schweikert.de /
          www.anwalt-schweikert.de
          <br />
          www.alpinrecht.eu / www.alpenrecht.de / www.alpin-recht.de          
        </p>
      </div>
    </Layout>
  )
}
